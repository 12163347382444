import type { OptionalQuery as OptionalQuery_1lc4x8j } from '../pages/admin/companies/[companyId]/users/[userId]/reports/[guestId]';
import type { OptionalQuery as OptionalQuery_3t6ozb } from '../pages/companies/[companyId]/users/[userId]/reports/[guestId]';
import type { OptionalQuery as OptionalQuery_9igby2 } from '../pages/guestReports/[id]';
import type { OptionalQuery as OptionalQuery_17hpk34 } from '../pages/reports/[guestId]';

export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "$500": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/500' as const, hash: url?.hash })
  },
  "$5HOSAjrmv7W8": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/5HOSAjrmv7W8' as const, hash: url?.hash })
  },
  "admin": {
    "actions": {
      _actionId: (actionId: string | number) => ({
        "edit": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/actions/[actionId]/edit' as const, query: { actionId }, hash: url?.hash })
        }
      }),
      "new": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/actions/new' as const, hash: url?.hash })
      }
    },
    "companies": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/companies' as const, hash: url?.hash }),
      _companyId: (companyId: string | number) => ({
        "aggregations": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/companies/[companyId]/aggregations' as const, query: { companyId }, hash: url?.hash })
        },
        "auditLogs": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/companies/[companyId]/auditLogs' as const, query: { companyId }, hash: url?.hash })
        },
        "edit": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/companies/[companyId]/edit' as const, query: { companyId }, hash: url?.hash })
        },
        "interviews": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/companies/[companyId]/interviews' as const, query: { companyId }, hash: url?.hash }),
          _interviewId: (interviewId: string | number) => ({
            $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/companies/[companyId]/interviews/[interviewId]' as const, query: { companyId, interviewId }, hash: url?.hash })
          })
        },
        "users": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/companies/[companyId]/users' as const, query: { companyId }, hash: url?.hash }),
          _userId: (userId: string | number) => ({
            "reports": {
              $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/companies/[companyId]/users/[userId]/reports' as const, query: { companyId, userId }, hash: url?.hash }),
              _guestId: (guestId: string | number) => ({
                $url: (url?: { query?: OptionalQuery_1lc4x8j | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/admin/companies/[companyId]/users/[userId]/reports/[guestId]' as const, query: { companyId, userId, guestId, ...url?.query }, hash: url?.hash })
              })
            }
          })
        }
      }),
      "new": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/companies/new' as const, hash: url?.hash })
      }
    },
    "partner_actions": {
      _partnerActionId: (partnerActionId: string | number) => ({
        "edit": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/partner_actions/[partnerActionId]/edit' as const, query: { partnerActionId }, hash: url?.hash })
        }
      }),
      "new": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/partner_actions/new' as const, hash: url?.hash })
      }
    },
    "solutions": {
      _solutionId: (solutionId: string | number) => ({
        "edit": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/solutions/[solutionId]/edit' as const, query: { solutionId }, hash: url?.hash })
        }
      }),
      "new": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/solutions/new' as const, hash: url?.hash })
      }
    },
    "suggestions": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/suggestions' as const, hash: url?.hash })
    },
    "surveys": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/surveys' as const, hash: url?.hash }),
      _surveyId: (surveyId: string | number) => ({
        "edit": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/surveys/[surveyId]/edit' as const, query: { surveyId }, hash: url?.hash })
        }
      }),
      "new": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/surveys/new' as const, hash: url?.hash })
      }
    },
    "users": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/users' as const, hash: url?.hash }),
      _adminId: (adminId: string | number) => ({
        "edit": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/users/[adminId]/edit' as const, query: { adminId }, hash: url?.hash })
        }
      }),
      "new": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/admin/users/new' as const, hash: url?.hash })
      }
    }
  },
  "companies": {
    _companyId: (companyId: string | number) => ({
      "auditLogs": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/companies/[companyId]/auditLogs' as const, query: { companyId }, hash: url?.hash })
      },
      "settings": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/companies/[companyId]/settings' as const, query: { companyId }, hash: url?.hash })
      },
      "users": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/companies/[companyId]/users' as const, query: { companyId }, hash: url?.hash }),
        _userId: (userId: string | number) => ({
          "management_reports": {
            _departmentUniqueKey: (departmentUniqueKey: string | number) => ({
              $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/companies/[companyId]/users/[userId]/management-reports/[departmentUniqueKey]' as const, query: { companyId, userId, departmentUniqueKey }, hash: url?.hash })
            })
          },
          "one_on_ones": {
            _oneOnOneId: (oneOnOneId: string | number) => ({
              "details": {
                _oneOnOneMeetingId: (oneOnOneMeetingId: string | number) => ({
                  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/companies/[companyId]/users/[userId]/one-on-ones/[oneOnOneId]/details/[oneOnOneMeetingId]' as const, query: { companyId, userId, oneOnOneId, oneOnOneMeetingId }, hash: url?.hash })
                })
              },
              $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/companies/[companyId]/users/[userId]/one-on-ones/[oneOnOneId]' as const, query: { companyId, userId, oneOnOneId }, hash: url?.hash })
            })
          },
          "reports": {
            $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/companies/[companyId]/users/[userId]/reports' as const, query: { companyId, userId }, hash: url?.hash }),
            _guestId: (guestId: string | number) => ({
              $url: (url?: { query?: OptionalQuery_3t6ozb | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/companies/[companyId]/users/[userId]/reports/[guestId]' as const, query: { companyId, userId, guestId, ...url?.query }, hash: url?.hash })
            })
          }
        })
      }
    })
  },
  "errors": {
    "denyIp": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/errors/denyIp' as const, hash: url?.hash })
    },
    "invalidOrganizationLogin": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/errors/invalidOrganizationLogin' as const, hash: url?.hash })
    },
    "invalidURL": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/errors/invalidURL' as const, hash: url?.hash })
    },
    "login": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/errors/login' as const, hash: url?.hash })
    },
    "passwordExpiration": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/errors/passwordExpiration' as const, hash: url?.hash })
    },
    "permission": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/errors/permission' as const, hash: url?.hash })
    }
  },
  "guestReports": {
    _id: (id: string | number) => ({
      $url: (url?: { query?: OptionalQuery_9igby2 | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/guestReports/[id]' as const, query: { id, ...url?.query }, hash: url?.hash })
    })
  },
  "healthz": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/healthz' as const, hash: url?.hash })
  },
  "home": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/home' as const, hash: url?.hash })
  },
  "invitations": {
    _invitationCode: (invitationCode: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/invitations/[invitationCode]' as const, query: { invitationCode }, hash: url?.hash })
    })
  },
  "logout": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/logout' as const, hash: url?.hash })
  },
  "management_reports": {
    _departmentUniqueKey: (departmentUniqueKey: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/management-reports/[departmentUniqueKey]' as const, query: { departmentUniqueKey }, hash: url?.hash })
    })
  },
  "movies": {
    _recordingId: (recordingId: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/movies/[recordingId]' as const, query: { recordingId }, hash: url?.hash })
    })
  },
  "one_on_one_meetings": {
    _oneOnOneId: (oneOnOneId: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/one-on-one-meetings/[oneOnOneId]' as const, query: { oneOnOneId }, hash: url?.hash })
    })
  },
  "one_on_ones": {
    _oneOnOneId: (oneOnOneId: string | number) => ({
      "details": {
        _oneOnOneMeetingId: (oneOnOneMeetingId: string | number) => ({
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/one-on-ones/[oneOnOneId]/details/[oneOnOneMeetingId]' as const, query: { oneOnOneId, oneOnOneMeetingId }, hash: url?.hash })
        })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/one-on-ones/[oneOnOneId]' as const, query: { oneOnOneId }, hash: url?.hash })
    })
  },
  "organization_login": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/organization-login' as const, hash: url?.hash })
  },
  "reports": {
    _guestId: (guestId: string | number) => ({
      $url: (url?: { query?: OptionalQuery_17hpk34 | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/reports/[guestId]' as const, query: { guestId, ...url?.query }, hash: url?.hash })
    })
  },
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash })
};

export type PagesPath = typeof pagesPath;
